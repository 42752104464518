/** @jsx jsx */
import { Label } from '@rebass/forms';
import { Flex } from 'rebass';
import { jsx } from 'theme-ui';
import { useTranslation } from '../i18n/TranslationContext';
import { TimezonePicker } from './TimezonePicker';

interface TimeZoneSwitcherProps {
  tzOffset: number | ((value: number | ((val: number) => number)) => void);
  changeTimeZones: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const TimeZoneSwitcher: React.FC<TimeZoneSwitcherProps> = ({
  tzOffset,
  changeTimeZones,
}) => {
  const t = useTranslation();

  return (
    <Flex
      sx={{
        mb: 4,
        flexDirection: 'column',
        maxWidth: ['100%', '100%', '43%'],
      }}
    >
      <Label
        htmlFor="timezonepicker"
        sx={{ variant: 'text.body', display: 'inline', mb: 2 }}
      >
        {t('program_times_shown_in')}:
      </Label>
      <TimezonePicker
        id="timezonepicker"
        initialValue={tzOffset as number}
        handleTimeZoneChange={changeTimeZones}
      />
    </Flex>
  );
};
