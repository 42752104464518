/** @jsx jsx */
import { jsx } from 'theme-ui';
import { createContext, useCallback, useContext } from 'react';
import { Children, Fragment } from 'react';
import { Box, Flex, FlexProps } from 'rebass';
import moment from 'moment';

import { Text } from './Text';
import { LocalizedLink as Link } from './LocalizedLink';
import { useTranslation } from '../i18n/TranslationContext';

const CalendarTime: React.FC = ({ children }) => {
  return <Text sx={{ fontWeight: 'normal', color: 'text' }}>{children}</Text>;
};

const CalendarBoldText: React.FC = ({ children }) => {
  return (
    <Text
      sx={{ fontWeight: 'bold', fontSize: 1, lineHeight: 1, color: 'text' }}
    >
      {children}
    </Text>
  );
};

const CalendarCell: React.FC<React.ComponentProps<'div'> &
  FlexProps & { hideDivider?: boolean }> = ({
  children,
  hideDivider,
  ...rest
}) => {
  return (
    <Fragment>
      <Flex flexDirection="column" mb={3} mt={2} {...rest}>
        {children}
      </Flex>
      {!hideDivider && (
        <hr
          sx={{
            width: '85%',
            borderColor: 'text',
            borderBottom: 'none',
            mx: 'auto',
            my: 0,
          }}
        />
      )}
    </Fragment>
  );
};

const SpeakerCalendarContext = createContext<{
  tzOffset: number;
  formatDate: (
    date: string | Date,
    format: string,
    disableTimeOffset?: boolean,
  ) => string;
}>({
  tzOffset: 0,
  formatDate: (date, _, __) => date.toString(),
});

interface CalendarProps {
  tzOffset: number;
  timeOffset: number;
}
export const Calendar: React.FC<CalendarProps> = ({
  children,
  tzOffset,
  timeOffset,
}) => {
  const formatDate = useCallback(
    (date: string | Date, format: string, disableTimeOffset?: boolean) => {
      const localMoment = moment(date + '-05:00')
        .utcOffset(tzOffset)
        .add(disableTimeOffset ? 0 : timeOffset, 'hour');
      return localMoment.format(format);
    },
    [tzOffset, timeOffset],
  );

  return (
    <SpeakerCalendarContext.Provider
      value={{
        tzOffset,
        formatDate,
      }}
    >
      <Flex
        sx={{
          display: 'grid',
          columnGap: 1,
          gridTemplateColumns: [
            '1fr',
            '1fr',
            `repeat(${Children.count(children)}, 1fr)`,
          ],
        }}
      >
        {children}
      </Flex>
    </SpeakerCalendarContext.Provider>
  );
};

const CalendarColumnContext = createContext<{
  formatTime: (date: string) => string;
  shouldOffsetTime?: boolean;
}>({
  formatTime: (date: string) => date,
  shouldOffsetTime: false,
});
interface CalendarColumnProps {
  datetime: string;
  href?: string;
  shouldOffsetTime?: boolean;
}

function withLinkOrComponent(Component) {
  /** renders either an <a> or a <Box> depending on the presence of an href in the props */
  return function LinkOrComponent({ ...props }) {
    if (props.to) {
      if (props.to.indexOf('http') === 0)
        return <a href={props.to} {...props} />;
      else return <Link to="" {...props} />;
    } else {
      return <Component {...props} />;
    }
  };
}

const LinkOrBox = withLinkOrComponent(Box);

export const CalendarColumn: React.FC<CalendarColumnProps> = ({
  children,
  datetime,
  href,
  shouldOffsetTime,
}) => {
  const { formatDate } = useContext(SpeakerCalendarContext);
  const t = useTranslation();
  const formatTime = useCallback(
    (time: string) => {
      return formatDate(
        `${datetime}T${time}`,
        t('program_time_format_calendar'),
        !shouldOffsetTime,
      );
    },
    [formatDate, datetime],
  );

  return (
    <CalendarColumnContext.Provider value={{ formatTime, shouldOffsetTime }}>
      <Flex flexDirection="column">
        <LinkOrBox
          to={href}
          sx={{
            boxSizing: 'border-box',
            padding: 1,
            textDecoration: 'none',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'transparent',
            '&:hover': {
              borderColor: 'text',
            },
          }}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            mb={4}
            mt={[4, 4, 0]}
          >
            <Text sx={{ color: 'text' }}>
              {formatDate(
                `${datetime}T${shouldOffsetTime ? '10:45' : '00:00'}`,
                'ddd DD MMM',
                !shouldOffsetTime,
              )}
            </Text>
          </Flex>
          {children}
        </LinkOrBox>
      </Flex>
    </CalendarColumnContext.Provider>
  );
};

interface CalendarKeynoteProps {
  time: string;
  speaker: string;
  speakerImg: string;
  hideDivider?: boolean;
  href?: string;
}
export const CalendarKeynote: React.FC<CalendarKeynoteProps> = ({
  time,
  speaker,
  speakerImg,
  hideDivider,
}) => {
  const { formatTime } = useContext(CalendarColumnContext);
  return (
    <CalendarCell
      justifyContent="space-between"
      flexDirection="row"
      hideDivider={hideDivider}
    >
      <Box mr={1}>
        <CalendarTime>{formatTime(time)}</CalendarTime>
        <CalendarBoldText>
          Keynote <span sx={{ fontWeight: 'normal' }}>by </span>
          {speaker}
        </CalendarBoldText>
      </Box>
      <img
        sx={{ height: '120px', width: '60px', mt: '-6px' }}
        css={{ objectFit: 'cover' }}
        srcSet={speakerImg}
      />
    </CalendarCell>
  );
};

interface CalendarEventProps {
  title: string;
  time?: string;
  href?: string;
  speaker?: string;
  forcedUnderline?: boolean;
  hideDivider?: boolean;
}
export const CalendarEvent: React.FC<CalendarEventProps> = ({
  title,
  time,
  href,
  forcedUnderline,
  speaker,
  hideDivider,
}) => {
  const { formatTime } = useContext(CalendarColumnContext);
  return (
    <CalendarCell hideDivider={hideDivider}>
      {time && <CalendarTime>{formatTime(time)}</CalendarTime>}
      <LinkOrBox
        to={href}
        sx={{
          variant: 'variants.link',
          textDecoration: forcedUnderline ? 'underline' : 'none',
          '&:hover': {
            textDecoration: forcedUnderline ? 'underline' : 'none',
          },
        }}
      >
        <CalendarBoldText>
          {title}
          {speaker && (
            <Fragment>
              <span sx={{ fontWeight: 'normal' }}> by </span>
              {speaker}
            </Fragment>
          )}
        </CalendarBoldText>
      </LinkOrBox>
    </CalendarCell>
  );
};
