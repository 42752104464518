/** @jsx jsx */
import { jsx } from 'theme-ui';
import moment from 'moment-timezone';
import { useLocalStorage } from './UseLocalStorage';
import React from 'react';

interface TimezonePickerProps {
  handleTimeZoneChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  initialValue: number;
}

const timezones = [
  { '(GMT -12:00) Eniwetok, Kwajalein': -12 },
  { '(GMT -11:00) Midway Island, Samoa': -11 },
  { '(GMT -10:00) Hawaii': -10 },
  { '(GMT -09:00) Alaska': -9 },
  { '(GMT -08:00) Pacific Time (US & Canada)': -8 },
  { '(GMT -07:00) Pacific Mountain Time (US & Canada)': -7 },
  { '(GMT -06:00) Central Time (US & Canada), Mexico City': -6 },
  { '(GMT -05:00) Eastern Time (US & Canada), Bogota, Lima': -5 },
  { '(GMT -04:00) Atlantic Time (Canada), Caracas, La Paz': -4 },
  { '(GMT -03:30) Newfoundland': -3.5 },
  { '(GMT -03:00) Brazil, Buenos Aires, Georgetown': -3 },
  { '(GMT -02:00) Mid-Atlantic': -2 },
  { '(GMT -01:00) Azores, Cape Verde Islands': -1 },
  { '(GMT) Western Europe Time, London, Lisbon, Casablanca': 0 },
  { '(GMT +01:00) Brussels, Copenhagen, Madrid, Paris': 1 },
  { '(GMT +02:00) Kaliningrad, South Africa': 2 },
  { '(GMT +03:00) Baghdad, Riyadh, Moscow, St. Petersburg': 3 },
  { '(GMT +03:30) Tehran': 3.5 },
  { '(GMT +04:00) Abu Dhabi, Muscat, Baku, Tbilisi': 4 },
  { '(GMT +04:30) Kabul': 4.5 },
  { '(GMT +05:00) Ekaterinburg, Islamabad, Karachi, Tashkent': 5 },
  { '(GMT +05:30) Bombay, Calcutta, Madras, New Delhi': 5.5 },
  { '(GMT +05:45) Kathmandu': 5.75 },
  { '(GMT +06:00) Almaty, Dhaka, Colombo': 6 },
  { '(GMT +07:00) Bangkok, Hanoi, Jakarta': 7 },
  { '(GMT +08:00) Beijing, Perth, Singapore, Hong Kong': 8 },
  { '(GMT +09:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk': 9 },
  { '(GMT +09:30) Adelaide, Darwin': 9.5 },
  { '(GMT +10:00) Eastern Australia, Guam, Vladivostok': 10 },
  { '(GMT +11:00) Magadan, Solomon Islands, New Caledonia': 11 },
  { '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka': 12 },
];

export function useLocalStorateTzOffset() {
  const [tzOffset, setTzOffset] = useLocalStorage<number>(
    'storedTimezoneOffset',
    moment.tz(moment.tz.guess()).utcOffset() / 60 || 0,
  );

  const changeTimeZones = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const target = e.target as HTMLSelectElement;
    const value = parseFloat(target.value);
    // @ts-ignore
    setTzOffset(value);
  };

  return { tzOffset, changeTimeZones };
}

const TimezonePicker: React.FC<TimezonePickerProps &
  React.ComponentProps<'select'>> = ({
  handleTimeZoneChange,
  initialValue,
  ...restProps
}) => {
  return (
    <select
      sx={{
        display: 'inline',
        width: 'auto',
        maxWidth: '100%',
        height: "32px",
        fontFamily: 'body',
        color: 'text',
        backgroundColor: 'transparent',
        borderColor: 'text',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: "0",
        px: 2,
        fontSize: 2,
        "-webkit-appearance": "none",
        '&:focus': {
          outline: 'none',
          borderWidth: "4px",
        },
      }}
      {...restProps}
      value={initialValue}
      onChange={handleTimeZoneChange}
    >
      {timezones.map(tz => {
        const tzName = Object.keys(tz)[0];
        const tzOffset = Object.values(tz)[0];

        return (
          <option value={tzOffset} key={tzOffset} sx={{ color: 'black' }}>
            {tzName}
          </option>
        );
      })}
    </select>
  );
};

export { TimezonePicker };
