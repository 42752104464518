/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { Children, cloneElement, useState } from 'react';
import { Container } from '../components/Container';
import { H2, H5, P } from '../components/Text';
import { Box, Button, Flex, FlexProps } from 'rebass';
import { useTranslation } from '../i18n/TranslationContext';
import { ProgramLayout } from '../layouts/ProgramLayout';
import {
  Calendar,
  CalendarKeynote,
  CalendarColumn,
  CalendarEvent,
} from '../components/SpeakerCalendar';
import { LocalizedLink } from '../components/LocalizedLink';
import { graphql } from 'gatsby';

interface ButtonGroupProps {
  initialSelectedIndex?: number;
  onSelect?: (index: number) => void;
}
const ButtonGroup: React.FC<ButtonGroupProps> = ({
  children,
  initialSelectedIndex = 0,
  onSelect,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);

  const nextChildren = Children.map(children, (c: any, index) => {
    const isBetween = index > 0 && index < Children.count(children) - 1;
    const isLeftEdge = index === 0;
    const isRightEdge = index === Children.count(children) - 1;

    return cloneElement(c, {
      variant: selectedIndex === index ? 'primary' : 'secondary',
      onClick: () => {
        setSelectedIndex(index);
        if (typeof onSelect === 'function') {
          onSelect(index);
        }
      },
      sx: {
        flex: 1,
        py: 0,
        borderRadius: isBetween ? 0 : null,
        borderTopRightRadius: isLeftEdge ? 0 : null,
        borderBottomRightRadius: isLeftEdge ? 0 : null,
        borderTopLeftRadius: isRightEdge ? 0 : null,
        borderBottomLeftRadius: isRightEdge ? 0 : null,
        ':hover': {
          transition: 'none',
          transform: 'none',
        },
      },
    });
  });
  return <Flex>{nextChildren}</Flex>;
};

const PickStreamSection: React.FC<FlexProps & {
  setSelectedStream: (index: number) => string;
}> = ({ setSelectedStream }) => {
  const t = useTranslation();
  return (
    <Flex justifyContent="flex-end">
      <Box width={[1, 1, 1 / 2]}>
        <H5>{t('program_pick_a_stream')}</H5>
        <ButtonGroup initialSelectedIndex={0} onSelect={setSelectedStream}>
          <Button>{t('program_americas')}</Button>
          <Button>{t('program_apac')}</Button>
          <Button>{t('program_emea')}</Button>
        </ButtonGroup>
        <hr
          sx={{
            borderColor: 'text',
            borderBottom: 'none',
            my: 3,
          }}
        />
      </Box>
    </Flex>
  );
};

interface ProgramOverviewProps {
  pageContext: { programDays: string[] };
  data: any;
}
const ProgramOverview: React.FC<ProgramOverviewProps> = ({
  pageContext: { programDays },
  data,
}) => {
  const [selectedStream, setSelectedStream] = React.useState(0);
  const t = useTranslation();

  const speakerImages = data.allImageSharp.edges.reduce(
    (acc: any, { node }: any) => {
      acc[node.fluid.originalName.split('.')[0].split('keynote_')[1]] =
        node.fluid.srcSet;
      return acc;
    },
    {},
  );

  return (
    <ProgramLayout programDays={programDays}>
      {({ tzOffset }) => (
        <Container>
          <H2>31 January - 5 February 2021</H2>
          <P>
            Interaction 21 is a 3-day conference, an{' '}
            <a
              href="https://www.edusummit.ixda.org/"
              sx={{ variant: 'variants.link' }}
            >
              Education Summit
            </a>
            , a{' '}
            <LocalizedLink
              to="/program/local-leaders-retreat"
              sx={{ variant: 'variants.link' }}
            >
              Local Leaders Retreat
            </LocalizedLink>
            , a day of{' '}
            <LocalizedLink
              to="/program/day_0"
              sx={{ variant: 'variants.link' }}
            >
              workshops
            </LocalizedLink>{' '}
            the{' '}
            <a
              href="https://awards.ixda.org/"
              sx={{ variant: 'variants.link' }}
            >
              Interaction Awards ceremony
            </a>
            , and a 4-day{' '}
            <a
              href="https://www.sdc.ixda.org/"
              sx={{ variant: 'variants.link' }}
            >
              Student Design Charette
            </a>
            .
          </P>
          <PickStreamSection setSelectedStream={setSelectedStream} />
          <Calendar tzOffset={tzOffset} timeOffset={selectedStream * 8}>
            <CalendarColumn datetime="2021-01-31" shouldOffsetTime>
              <CalendarEvent
                title="Interaction Design Education Summit Day 1"
                href="https://www.edusummit.ixda.org/"
                hideDivider
                forcedUnderline
              />
              <CalendarEvent
                time="14:00"
                title="Welcome to the Education Summit"
                hideDivider
              />
              <CalendarEvent
                time="14:30"
                title="Opening Keynote"
                speaker="Kris De Decker"
              />
            </CalendarColumn>
            <CalendarColumn datetime="2021-02-01" shouldOffsetTime>
              <CalendarEvent
                title="Interaction Design Education Summit Day 2"
                href="https://www.edusummit.ixda.org/"
                hideDivider
                forcedUnderline
              />
              <CalendarEvent
                time="09:00"
                title="Conversation: “Perilous times on the time scale: what to be trained for?”"
                hideDivider
              />

              <CalendarEvent time="16:00" title="Closing words" />
              <CalendarEvent
                title="Local Leader's retreat"
                href="/program/local-leaders-retreat"
                forcedUnderline
              />
            </CalendarColumn>

            <CalendarColumn
              datetime="2021-02-02"
              href="/program/day_0"
              shouldOffsetTime
            >
              <CalendarEvent title="Workshop day - Refer to the times on your workshop tickets" />
              <CalendarEvent time="11:00" title="Workshops start" />
              <CalendarEvent time="13:30" title="Workshops cont'd" />
            </CalendarColumn>

            <CalendarColumn
              datetime="2021-02-03"
              href="/program/day_1"
              shouldOffsetTime
            >
              <CalendarEvent title="Anger" />
              <CalendarEvent time="10:45" title="Welcome to Interaction 21" />
              <CalendarKeynote
                time="11:05"
                speaker="Aza Raskin"
                speakerImg={speakerImages['aza_raskin']}
              />
              <CalendarEvent time="12:00" title="Morning talks" />
              <CalendarEvent time="14:00" title="Lunch Show 👩🏾‍🍳" />
              <CalendarEvent time="15:00" title="Afternoon talks" />
              <CalendarEvent
                time="16:50"
                title="Panel: Decentralization of Silicon Valley"
              />
              <CalendarKeynote
                time="17:20"
                speaker="Ariel Muller"
                speakerImg={speakerImages['ariel_muller']}
              />
              <CalendarEvent time="18:20" title="Closing remarks" />
            </CalendarColumn>

            <CalendarColumn
              datetime="2021-02-04"
              href="/program/day_2"
              shouldOffsetTime
            >
              <CalendarEvent title="Accountability" />
              <CalendarEvent time="11:15" title="Day 2 - Morning show" />
              <CalendarKeynote
                time="11:20"
                speaker="Odile Joannette"
                speakerImg={speakerImages['odile_joanette']}
              />
              <CalendarEvent time="11:55" title="Morning talks" />
              <CalendarEvent time="14:00" title="Lunch show 🌈" />
              <CalendarEvent time="15:00" title="Afternoon talks" />
              <CalendarEvent
                time="16:55"
                title="Panel: Racism / Homophobia in Design"
              />
              <CalendarKeynote
                time="17:15"
                speaker="Webster"
                speakerImg={speakerImages['webster']}
              />
              <CalendarEvent time="18:30" title="Closing remarks" />
            </CalendarColumn>

            <CalendarColumn
              datetime="2021-02-05"
              href="/program/day_3"
              shouldOffsetTime
            >
              <CalendarEvent title="Action" />
              <CalendarEvent time="10:50" title="Day 3 - Morning show" />
              <CalendarKeynote
                time="11:00"
                speaker="Brenda Romero"
                speakerImg={speakerImages['brenda_romero']}
              />
              <CalendarEvent time="12:00" title="Morning talks" />
              <CalendarEvent time="14:00" title="Lunch Show 🎤" />
              <CalendarEvent time="15:00" title="Afternoon talks" />
              <CalendarKeynote
                time="17:15"
                speaker="David Brin"
                speakerImg={speakerImages['david_brin']}
              />
              <CalendarEvent time="18:15" title="Parting words" />
              <CalendarEvent time="19:00" title="IxD Awards Ceremony" />
            </CalendarColumn>
          </Calendar>
        </Container>
      )}
    </ProgramLayout>
  );
};

export const keynotesImage = graphql`
  query keynoteImages {
    allImageSharp(
      filter: { fluid: { originalName: { regex: "/^keynote/" } } }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 120, maxHeight: 240) {
            ...GatsbyImageSharpFluid
            originalName
          }
        }
      }
    }
  }
`;

export default ProgramOverview;
