/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Flex } from 'rebass';

import { useLocale } from '../i18n/LocaleContext';
import { useTranslation } from '../i18n/TranslationContext';
import MainLayout from '../layouts/MainLayout';
import { LocalizedLink } from '../components/LocalizedLink';
import { useLocalStorateTzOffset } from '../components/TimezonePicker';
import { H2 } from '../components/Text';
import { Container } from '../components/Container';
import { TimeZoneSwitcher } from '../components/TimeZoneSwitcher';

interface ProgramLayoutProps {
  programDays: string[];
  children: ({ tzOffset }: { tzOffset: number }) => React.ReactNode;
}

const activeStyle = { fontWeight: 'bold' };

export const ProgramLayout: React.FC<ProgramLayoutProps> = ({
  children,
  programDays,
}) => {
  const { locale, basePath } = useLocale();
  const { tzOffset, changeTimeZones } = useLocalStorateTzOffset();
  const t = useTranslation();

  const enDayLabels = ['Workshops', 'Day 1', 'Day 2', 'Day 3'];
  const frDayLabels = ['Ateliers', 'Jour 1', 'Jour 2', 'Jour 3'];

  const programPath = '/program';
  const programDayLinks = programDays.map((d, i) => ({
    path: `${programPath}/day_${d}`,
    label: { en: enDayLabels[i], fr: frDayLabels[i] },
  }));
  const programLinks = [
    { path: `${programPath}`, label: { en: 'Overview', fr: 'Survol' } },
    ...programDayLinks,
    {
      path: `${programPath}/speakers`,
      label: { en: 'Speakers', fr: 'Conférenciers' },
    },
    { path: `${programPath}/social`, label: { en: 'Social', fr: 'Social' } },
  ];

  return (
    <MainLayout>
      <Container>
        <Flex
          sx={{
            pb: [3, 4],
            mb: [3, 4],
            borderBottom: 'solid 1px',
            borderColor: 'text',
            justifyContent: 'space-between',
            flexDirection: ['column', 'column', 'row'],
          }}
        >
          <Box>
            <H2 sx={{ mb: 3 }} id="content">
              {t('program_heading')}
            </H2>
            <Flex
              sx={{
                mb: [3, 3, 0],
                flexWrap: 'wrap',
              }}
            >
              {programLinks.map(link => {
                return (
                  <LocalizedLink
                    key={link.path}
                    sx={{
                      variant: 'variants.navlink',
                      mr: 3,
                      mb: 3,
                      ...(basePath === link.path
                        ? activeStyle
                        : { textDecoration: 'underline' }),
                    }}
                    to={link.path}
                  >
                    {link.label[locale]}
                  </LocalizedLink>
                );
              })}
            </Flex>
          </Box>
          <TimeZoneSwitcher
            tzOffset={tzOffset}
            changeTimeZones={changeTimeZones}
          />
        </Flex>
      </Container>
      {typeof children === 'function'
        ? children({ tzOffset: tzOffset as number })
        : children}
    </MainLayout>
  );
};
